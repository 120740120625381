<template>
  <div class="intro full">
    <van-nav-bar title="退款进度" >
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="ab_content">
      <div class="rf_out">
        <div class="rf_title">
          <div class="title" v-if="info.orderRefund.status == 1">运营审核中</div>
          <div class="title" v-if="info.orderRefund.status == 2">退票中</div>
          <div class="title" v-if="info.orderRefund.status == 3">退票失败</div>
          <div class="title" v-if="info.orderRefund.status == 4">退款中</div>
          <div class="title" v-if="info.orderRefund.status == 5">退款失败</div>
          <div class="title" v-if="info.orderRefund.status == 6">退款成功</div>
          <div class="title" v-if="info.orderRefund.status == 7">财务审核中</div>
          <div class="title" v-if="info.orderRefund.status == 8">审核失败</div>
          <div class="sub" v-if="info.orderRefund.status == 6">退款已原来退回至您的支付账户，如有疑问请拨打客服查询。</div>
        </div>
        <div class="rf_detail">
          <div class="d_f ali_c">
            <span class="title">退款金额</span>
            <div class="flex_1">
              <span class="price">￥{{info.orderRefund.trueRefundAmount ? (info.orderRefund.trueRefundAmount/100).toFixed(2) : 0}}</span><span>(共{{info.detailsCount}}张)</span>
            </div>
          </div>
          <div class="d_f ali_c">
            <span class="title">退回账户</span>
            <div class="flex_1">
              原路退回
            </div>
          </div>
          <div class="d_f ali_c">
            <span class="title">退款流水号</span>
            <div class="flex_1">
              {{info.orderRefund.refundNo}}
            </div>
          </div>
        </div>
        <div class="rf_step">
          <van-steps direction="vertical" :active="active">
            <van-step>
              <!--creatorTime-->
              <div class="title">提交退款申请</div>
              <div class="date">{{info.orderRefund.creatorTime}}</div>
            </van-step>
            <van-step v-if="info.orderRefund.status === 6">
              <div class="title">系统审核通过，实际退款金额￥{{info.orderRefund.trueRefundAmount ? (info.orderRefund.trueRefundAmount/100).toFixed(2) : 0}}</div>
              <div class="date">{{info.orderRefund.refundNoticeTime}}</div>
            </van-step>
            <van-step v-if="info.orderRefund.status === 5">
              <div class="title">退款失败</div>
              <div class="date">{{info.orderRefund.refundNoticeTime}}</div>
            </van-step>
          </van-steps>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { NavBar, Icon, Steps, Step} from 'vant'
  Date.prototype.format=function (){
    var s='';
    s+=this.getFullYear()+'-';          // 获取年份。
    s+=(this.getMonth()+1)+"-";         // 获取月份。
    s+= this.getDate()+" ";                 // 获取日。
    s+= this.getHours()+":";                 // 获取小时。
    s+= this.getMinutes()+":";                 // 获取分。
    s+= this.getSeconds();                 // 获取秒。
    return(s);                          // 返回日期。
  };
  export default {
    name: "refund",
    components:{
      'van-nav-bar': NavBar,
      'van-icon': Icon,
      'van-steps': Steps,
      'van-step': Step,
    },
    data() {
      return {
        apis:{
          findreFundByOrderNumber:'stlsOrder/findreFundByOrderNumber',
        },
        info:{orderRefund:{}},
        active:1,
      }
    },
    created(){
      let me = this;
      me.$get({
        url:me.apis.findreFundByOrderNumber,
        params: {
          token:me.$global.token,
          orderNumber:me.$route.query.orderNumber
        }
      }).then(res => {
        me.info = res;
        if(res.status === 0){
          me.active = 0
        }
        if(res.status === 6){
          me.active = 1
        }
        if(res.status === 5){
          me.active = 1
        }
      });
    },
    methods: {
      exit(){
        // this.$destroy('KeepAlive')
        sessionStorage.clear();
        sessionStorage.setItem('LOGIN_STATUS',2);
        sessionStorage.setItem('token',this.$global.token);
        this.$router.go(-1)
      },}
  }
</script>

<style scoped lang="scss">
  .van-step__title{
    .title{
      font-size:14px;

      font-weight:500;
    }
    .date{
      font-size:12px;

      font-weight:400;
    }
  }
  .rf_step{
    padding: 12px 20px;
  }
  .rf_detail{
    >div{
      margin-bottom: 13px;
    }
    .price{
      color: #FF3E6E;
    }
    .title{
      width: 92px;
    }
    font-size:12px;

    font-weight:400;
    color:rgba(20,18,18,1);
    padding: 15px 8px 0 8px;
    border-bottom: 1px solid #D3D3D3;
  }
  .rf_title{
    .sub{
      font-size:12px;

      font-weight:400;
      color:rgba(20,18,18,1);
    }
    .title{
      font-size:16px;

      font-weight:bold;
      color:rgba(20,18,18,1);
      margin-bottom: 12px;
    }
    padding: 12px 8px 9px 8px;
    border-bottom: 1px solid #D3D3D3;
  }
  .rf_out{
    background:rgba(255,255,255,1);
    border-radius:5px;
  }
  .intro .van-nav-bar{
    border-bottom: none;
  }
  .intro{
    height: 100%;
  }
  .ab_content{
    padding: 61px 15px 15px 15px;
    background:#EEEEEE;
    height: 100%;
    overflow-x: auto;
    box-sizing: border-box;
  }
</style>
